; (function ($, window, document, undefined) {
    'use strict';
    // nom de librairie à modifier
    Foundation.libs.mastertag = {
        name: 'mastertag',

        version: '1.0.0',

        settings: {
            trigger: '',    // 'click' ou 'autopromo'
            event: '',     // 'C' ou 'autopromo_click'
            type: '',     // 'N'=Navigation, 'S'=Sortie, 'A'=Action, 'T'=Téléchargement
            level: '',      // N° de site de Niveau 2 AT Internet
            label: '',      // Chapitrage AT Internet avec notation chapitre::sous_chapitre::clic
            name: '',       // Libellé de l'event
            product_size: '',       // Taille du produit
            product_engraving: '',  // '0'=Pas de gravure, '1'=Avec Gravure
            product_add_type: '',   // 'classique', 'catalogue', 'mixte' => Type d'ajout au panier
            completeCallback: function (request, status) { }
        },

        init: function (scope, method, options) {
            this.settings = this.settings || $.extend({}, this.defaults, (options || method));

            // à conserver
            this.bindings(method, options);
        },

        // Méthode globale 
        track_event: function (obj, trigger, options) {
            if (tC && tC.event) {
                switch (trigger) {
                    case "clic_zdm":
                        if (typeof tC.event.clic_zdm === "function") {
                            tC.event.clic_zdm(this, options);
                        }
                        break;
                    case "clic_zone":
                        if (typeof tC.event.clic_zone === "function") {
                            tC.event.clic_zone(this, options);
                        }
                        break;
                    case "identification":
                        if (typeof tC.event.identification === "function") {
                            tC.event.identification(this, options);
                        }
                        break;
                    case "login":
                        if (typeof tC.event.login === "function") {
                            tC.event.login(this, options);
                        }
                        break;
                    case "inscription_newsletter": // non existant pour la partie formulaire
                        if (typeof tC.event.inscription_newsletter === "function") {
                            tC.event.inscription_newsletter(this, options);
                        }
                        break;
                    case "clic_guide_tailles": // non existant
                        if (typeof tC.event.clic_guide_tailles === "function") {
                            tC.event.clic_guide_tailles(this, options);
                        }
                        break;
                    case "clic_favoris":
                        if (typeof tC.event.clic_favoris === "function") {
                            tC.event.clic_favoris(this, options);
                        }
                        break;
                    case "contact":
                        if (typeof tC.event.contact === "function") {
                            tC.event.contact(this, options);
                        }
                        break;
                    case "clic_carrousel":
                        if (typeof tC.event.clic_carrousel === "function") {
                            tC.event.clic_carrousel(this, options);
                        }
                        break;
                    case "clic_carrousel_produits":
                        if (typeof tC.event.clic_carrousel_produits === "function") {
                            tC.event.clic_carrousel_produits(this, options);
                        }
                        break;
                    case "clic_meilleures_ventes":
                        if (typeof tC.event.clic_meilleures_ventes === "function") {
                            tC.event.clic_meilleures_ventes(this, options);
                        }
                        break;
                    case "cross_sell":
                        if (typeof tC.event.cross_sell === "function") {
                            tC.event.cross_sell(this, options);
                        }
                        break;
                    case "must_have":
                        if (typeof tC.event.must_have === "function") {
                            tC.event.must_have(this, options);
                        }
                        break;
                    case "derniers_articles_vus":
                        if (typeof tC.event.derniers_articles_vus === "function") {
                            tC.event.derniers_articles_vus(this, options);
                        }
                        break;
                    case "suppression_produit":
                        if (typeof tC.event.suppression_produit === "function") {
                            tC.event.suppression_produit(this, options);
                        }
                        break;
                    case "emballage_cadeau":
                        if (typeof tC.event.emballage_cadeau === "function") {
                            tC.event.emballage_cadeau(this, options);
                        }
                        break;
                    case "up_sell":
                        if (typeof tC.event.up_sell === "function") {
                            tC.event.up_sell(this, options);
                        }
                        break;
                    case "ajout_produit":
                        if (typeof tC.event.ajout_produit === "function") {
                            tC.event.ajout_produit(this, options);
                        }
                        break;
                    case "ajout_panier":
                        if (typeof tC.event.ajout_panier === "function") {
                            tC.event.ajout_panier(this, options);
                        }
                        break;
                    case "onglet_fp":
                        if (typeof tC.event.onglet_fp === "function") {
                            tC.event.onglet_fp(this, options);
                        }
                        break;
                    case "fil_d_ariane":
                        if (typeof tC.event.fil_d_ariane === "function") {
                            tC.event.fil_d_ariane(this, options);
                        }
                        break;
                    case "saisie_gravure":
                        if (typeof tC.event.saisie_gravure === "function") {
                            tC.event.saisie_gravure(this, options);
                        }
                        break;
                    case "validation_gravure":
                        if (typeof tC.event.validation_gravure === "function") {
                            tC.event.validation_gravure(this, options);
                        }
                        break;
                    case "footer":
                        if (typeof tC.event.footer === "function") {
                            tC.event.footer(this, options);
                        }
                        break;
                    case "e_reservation":
                        if (typeof tC.event.e_reservation === "function") {
                            tC.event.e_reservation(this, options);
                        }
                        break;
                    case "clic_menu":
                        if (typeof tC.event.clic_menu === "function") {
                            tC.event.clic_menu(this, options);
                        }
                        break;
                    case "search":
                        if (typeof tC.event.search === "function") {
                            tC.event.search(this, options);
                        }
                        break;
                    case "decouvrez_aussi":
                        if (typeof tC.event.decouvrez_aussi === "function") {
                            tC.event.decouvrez_aussi(this, options);
                        }
                        break;
                    case "demande_catalogue":
                        if (typeof tC.event.demande_catalogue === "function") {
                            tC.event.demande_catalogue(this, options);
                        }
                        break;
                    case "validation_demande_catalogue":
                        if (typeof tC.event.validation_demande_catalogue === "function") {
                            tC.event.validation_demande_catalogue(this, options);
                        }
                        break;
                    case "promotion":
                        if (typeof tC.event.promotion === "function") {
                            tC.event.promotion(this, options);
                        }
                        break;
                    case "offre_du_moment":
                        if (typeof tC.event.offre_du_moment === "function") {
                            tC.event.offre_du_moment(this, options);
                        }
                        break;
                    case "opt_in":
                        if (typeof tC.event.opt_in === "function") {
                            tC.event.opt_in(this, options);
                        }
                        break;
                    case "clic_liste_produit":
                        if (typeof tC.event.clic_liste_produit === "function") {
                            tC.event.clic_liste_produit(this, options);
                        }
                        break;
                    case "filtres":
                        if (typeof tC.event.filtres === "function") {
                            tC.event.filtres(this, options);
                        }
                        break;
                    case "validation_webcallback":
                        if (typeof tC.event.validation_webcallback === "function") {
                            tC.event.validation_webcallback(this, options);
                        }
                        break;
                    case "demande_kit_rmp":
                        if (typeof tC.event.demande_kit_rmp === "function") {
                            tC.event.demande_kit_rmp(this, options);
                        }
                        break;
                    case "validation_demande_kit_rmp":
                        if (typeof tC.event.validation_demande_kit_rmp === "function") {
                            tC.event.validation_demande_kit_rmp(this, options);
                        }
                        break;
                    case "demande_creation_speciale":
                        if (typeof tC.event.demande_creation_speciale === "function") {
                            tC.event.demande_creation_speciale(this, options);
                        }
                        break;
                    case "validation_demande_creation_speciale":
                        if (typeof tC.event.validation_demande_creation_speciale === "function") {
                            tC.event.validation_demande_creation_speciale(this, options);
                        }
                        break;
                    case "demande_kit_produit_occasion":
                        if (typeof tC.event.demande_kit_produit_occasion === "function") {
                            tC.event.demande_kit_produit_occasion(this, options);
                        }
                        break;
                    case "validation_demande_kit_produit_occasion":
                        if (typeof tC.event.validation_demande_kit_produit_occasion === "function") {
                            tC.event.validation_demande_kit_produit_occasion(this, options);
                        }
                        break;
                    case "validation_panier":
                        if (typeof tC.event.validation_panier === "function") {
                            tC.event.validation_panier(this, options);
                        }
                        break;
                    case "code_promo_vide":
                        if (typeof tC.event.code_promo_vide === "function") {
                            tC.event.code_promo_vide(this, options);
                        }
                        break;
                    case "mdp_ko":
                        if (typeof tC.event.mdp_ko === "function") {
                            tC.event.mdp_ko(this, options);
                        }
                        break;
                    case "bijouterie_indispo":
                        if (typeof tC.event.bijouterie_indispo === "function") {
                            tC.event.bijouterie_indispo(this, options);
                        }
                        break;
                    case "email_vide":
                        if (typeof tC.event.email_vide === "function") {
                            tC.event.email_vide(this, options);
                        }
                        break;
                    case "choix_livraison":
                        if (typeof tC.event.choix_livraison === "function") {
                            tC.event.choix_livraison(this, options);
                        }
                        break;
                    case "choix_paiement":
                        if (typeof tC.event.choix_paiement === "function") {
                            tC.event.choix_paiement(this, options);
                        }
                        break;
                    case "modification_paiement":
                        if (typeof tC.event.modification_paiement === "function") {
                            tC.event.modification_paiement(this, options);
                        }
                        break;
                    case "wishlist_partage":
                        if (typeof tC.event.wishlist_partage === "function") {
                            tC.event.wishlist_partage(this, options);
                        }
                        break;
                    case "c_and_c_request":
                        if (typeof tC.event.c_and_c_request === "function") {
                            tC.event.c_and_c_request(this, options);
                        }
                        break;
                    case "c_and_c_confirmation":
                        if (typeof tC.event.c_and_c_confirmation === "function") {
                            tC.event.c_and_c_confirmation(this, options);
                        }
                        break;
                    case "express_paypal":
                        if (typeof tC.event.express_paypal === "function") {
                            tC.event.express_paypal(this, options);
                        }
                        break;
                    case "clic_fidelite_informations":
                        if (typeof tC.event.clic_fidelite_informations === "function") {
                            tC.event.clic_fidelite_informations(this, options);
                        }
                        break;
                    case "inscription_fidelite":
                        if (typeof tC.event.inscription_fidelite === "function") {
                            tC.event.inscription_fidelite(this, options);
                        }
                        break;
                    case "remise_fidelite":
                        if (typeof tC.event.remise_fidelite === "function") {
                            tC.event.remise_fidelite(this, options);
                        }
                        break;
                    case "validation_fidelite":
                        if (typeof tC.event.validation_fidelite === "function") {
                            tC.event.validation_fidelite(this, options);
                        }
                        break;
                    case "configurateur_selection":
                        if (typeof tC.event.configurateur_selection === "function") {
                            tC.event.configurateur_selection(this, options);
                        }
                        break;
                    case "configurateur_validation_globale":
                        if (typeof tC.event.configurateur_validation_globale === "function") {
                            tC.event.configurateur_validation_globale(this, options);
                        }
                        break;
                    case "clic_type_identification":
                        if (typeof tC.event.clic_type_identification === "function") {
                            tC.event.clic_type_identification(this, options);
                        }
                        break;
                    case "guide_des_tailles":
                        if (typeof tC.event.guide_des_tailles === "function") {
                            tC.event.guide_des_tailles(this, options);
                        }
                        break;
                    default: break;
                }
            }

            // TODO : à supprimer une fois tous les appels réécris
            if (typeof (tc_events_1) != "undefined") {
                tc_events_1(obj, trigger, options);
            }
            if (typeof (tc_events_3) != "undefined") {
                tc_events_3(obj, trigger, options);
            }
            else if (typeof (tc_events_5) != "undefined") {
                tc_events_5(obj, trigger, options);
            }

            if (typeof (tc_vars) != "undefined") {
                if (tc_vars['env_work'] != 'PRODUCTION') {
                    console.log("track_event : '" + trigger + "' // options = " + JSON.stringify(options));
                }
            }
        },

        // Méthode de lancement d'événement Tag Commander à appeler depuis les autes librairies JS
        // $(document).foundation('mastertag', 'track_tc_event', data);
        // "data" doit être de la forme suivante :
        // {
        //   obj: this, // élément du dom déclencheur de l'action
        //   trigger: 'add_to_cart_confirmed',  // nom de l'événement dans TagCommander
        //   options: { prop1: 'val1', prop2: 'val2'}  // valeurs complémentaires n'étant pas accessibles depuis tc_vars
        // }
        track_tc_event: function (data) {

            this.track_event(data.obj, data.trigger, data.options);

        },

        track_action: function (data) {
            if (tc_vars['env_work'] != 'PRODUCTION') {
                console.log('** EVENT OBSOLETE ** : méthode "track_action" est obsolète : utiliser la méthode track_tc_event à la place.');
                console.log(data);
            }
        },
        trackIdentificationClient: function (action) {
            if (tc_vars['env_work'] != 'PRODUCTION') {
                console.log('** EVENT OBSOLETE ** : méthode "trackIdentificationClient" est obsolète : utiliser la méthode track_tc_event à la place.');
                console.log(action);
            }
        },

        trackAddToCart: function (infos) {
            var options = {
                category: infos.category,
                action: tc_vars.order_last_product_added.product_category,
                label: tc_vars.order_last_product_added.product_name + ' - ' + tc_vars.order_last_product_added.product_id,
                value: tc_vars.order_last_product_added.product_unitprice_ati,
                product_cat1: tc_vars.order_last_product_added.product_cat1,
                product_cat2: tc_vars.order_last_product_added.product_cat2,
                product_cat3: tc_vars.order_last_product_added.product_cat3,
                product_category: tc_vars.order_last_product_added.product_category,
                product_currency: tc_vars.order_last_product_added.product_currency,
                product_discount_tf: tc_vars.order_last_product_added.product_discount_tf,
                product_discount_ati: tc_vars.order_last_product_added.product_discount_ati,
                product_engraving: tc_vars.order_last_product_added.product_engraving,
                product_gravable: tc_vars.order_last_product_added.product_gravable,
                product_id: tc_vars.order_last_product_added.product_id,
                product_name: tc_vars.order_last_product_added.product_name,
                product_promo: tc_vars.order_last_product_added.product_promo,
                product_promo_label: tc_vars.order_last_product_added.product_promo_label,
                product_quantity: tc_vars.order_last_product_added.product_quantity,
                product_rating: tc_vars.order_last_product_added.product_rating,
                product_size: tc_vars.order_last_product_added.product_size,
                product_trademark: tc_vars.order_last_product_added.product_trademark,
                product_unitprice_ati: tc_vars.order_last_product_added.product_unitprice_ati,
                product_unitprice_tf: tc_vars.order_last_product_added.product_unitprice_tf
            };

            this.track_event(this, infos.trigger, options);
        },

        trackClickAndCollectClick: function () {
            var event = {
                obj: "",
                trigger: 'c_and_c_request',
                options: {
                    category: "click & collect",
                    action: "Retrait bijouterie",
                    label: '',
                    value: ''
                }
            };

            this.track_event(this, event.trigger, event.options);
        },

        trackClickAndCollectJewelleryClick: function () {
            var event = {
                obj: "",
                trigger: 'c_and_c_request',
                options: {
                    category: "click & collect validation",
                    action: tc_vars.order_last_product_added.product_category,
                    label: tc_vars.order_last_product_added.product_name + ' - ' + tc_vars.order_last_product_added.product_id,
                    value: tc_vars.order_last_product_added.product_unitprice_ati,
                    product_cat1: tc_vars.order_last_product_added.product_cat1,
                    product_cat2: tc_vars.order_last_product_added.product_cat2,
                    product_cat3: tc_vars.order_last_product_added.product_cat3,
                    product_category: tc_vars.order_last_product_added.product_category,
                    product_currency: tc_vars.order_last_product_added.product_currency,
                    product_discount_tf: tc_vars.order_last_product_added.product_discount_tf,
                    product_discount_ati: tc_vars.order_last_product_added.product_discount_ati,
                    product_engraving: tc_vars.order_last_product_added.product_engraving,
                    product_gravable: tc_vars.order_last_product_added.product_gravable,
                    product_id: tc_vars.order_last_product_added.product_id,
                    product_name: tc_vars.order_last_product_added.product_name,
                    product_promo: tc_vars.order_last_product_added.product_promo,
                    product_promo_label: tc_vars.order_last_product_added.product_promo_label,
                    product_quantity: tc_vars.order_last_product_added.product_quantity,
                    product_rating: tc_vars.order_last_product_added.product_rating,
                    product_shipping: "MAG",
                    product_size: tc_vars.order_last_product_added.product_size,
                    product_trademark: tc_vars.order_last_product_added.product_trademark,
                    product_unitprice_ati: tc_vars.order_last_product_added.product_unitprice_ati,
                    product_unitprice_tf: tc_vars.order_last_product_added.product_unitprice_tf
                }
            };

            this.track_event(this, event.trigger, event.options);
        },

        events: function () {
            var self = this;
            var S = this.S;

            $(this.scope)
                .off('.fndtn.mastertag')
                .on('click.fndtn.mastertag', '[' + self.attr_name() + ']', function (evt) {

                    self.settings = $.extend({}, self.defaults, self.data_options($(this)));

                    // TODO : rechercher chaque data-trigger et analyser si encore besoin ! Idem à l'intérieur de autopromo
                    var trigger = $(this).data('trigger');

                    switch (trigger) {
                        case "out-social":
                            var opt = {
                                event: 'S',
                                level: '52',
                                label: self.settings['page_zone'] + "::" + 'social_network::' + self.settings['platform'],
                                name: self.settings['platform']
                            };
                            self.track_event(this, 'click', opt);

                            break;
                        case "out-game":
                            var opt = {
                                event: 'S',
                                level: '43', //jeu
                                label: self.settings['page_zone'] + "::" + self.settings['target_label'],
                                name: self.settings['target_name']
                            };
                            self.track_event(this, 'click', opt);

                            break;

                        case "download":
                            var opt = {
                                event: 'T',
                                label: self.settings['label']
                            };
                            self.track_event(this, 'click', opt);

                            break;
                        case "share":

                            if (typeof (tc_vars) != "undefined") {
                                var opt = {
                                    event: 'A',
                                    level: '52',
                                    label: 'social_share::' + self.settings['platform'] + '::' + tc_vars['page_name'],
                                    name: self.settings['platform']
                                };
                                self.track_event(this, 'click', opt);
                            }

                            break;

                        case "autopromo":

                            if (typeof (tc_vars) != "undefined") {
                                var evt_opt = {
                                    event: 'autopromo_click',
                                    format: '',
                                    produit: '',
                                    xtapx: 0
                                };

                                var action_type = self.settings['action_type'];
                                if (typeof (action_type) != "undefined") {
                                    switch (action_type) {
                                        // Homepage
                                        case 'homeCarousel':
                                        case 'link':
                                            evt_opt = {
                                                category: 'zone',
                                                action: Foundation.libs.common.decodeHTMLEntities($(this).attr("title")),
                                                label: ($(this).hasClass("fbo")) ? decodeURIComponent(window.atob($(this).data("href"))) : $(this).attr("href"),
                                                value: self.settings['zone']
                                            };

                                            self.track_event(this, 'clic_zone', evt_opt);
                                            break;
                                        case 'homeProduct':
                                        case 'carrouselProduct':
                                            evt_opt = {
                                                category: self.settings['carrousel_type'],
                                                action: self.settings['product_name'].replace(new RegExp("'", 'g'), '') + ' - ' + self.settings['product_id'].replace(new RegExp("'", 'g'), ''),
                                                label: $(this).attr("href"),
                                                value: self.settings['product_price']
                                            };

                                            self.track_event(this, 'clic_carrousel_produits', evt_opt);
                                            break;
                                        case 'homeEditorial':
                                            evt_opt['xtapx'] = '3';
                                            if (typeof (self.settings['action_name']) != 'undefined')
                                                evt_opt['format'] = self.settings['action_name'].replace(new RegExp(" ", 'g'), '_').toLowerCase();

                                            self.track_event(this, 'autopromo', evt_opt);
                                            break;

                                        // Fiche produit
                                        case 'productSuggestion':
                                            evt_opt['xtapx'] = '7';
                                            if (typeof (self.settings['product_id']) != 'undefined')
                                                evt_opt['produit'] = self.settings['product_id'].replace(new RegExp("'", 'g'), ''); // Si on n'ajoute pas d'apostrophes, la référence perd le zéro inital après appel de la fonction data_options() de foundation

                                            self.track_event(this, 'autopromo', evt_opt);
                                            break;
                                        case 'productLook':
                                            evt_opt['xtapx'] = '8';
                                            if (typeof (self.settings['product_id']) != 'undefined')
                                                evt_opt['produit'] = self.settings['product_id'].replace(new RegExp("'", 'g'), ''); // Si on n'ajoute pas d'apostrophes, la référence perd le zéro inital après appel de la fonction data_options() de foundation

                                            self.track_event(this, 'autopromo', evt_opt);
                                            break;

                                        // Montres de marques
                                        case 'mdmCarrousel':
                                            evt_opt['xtapx'] = '4';
                                            if (typeof (self.settings['action_name']) != 'undefined')
                                                evt_opt['format'] = self.settings['action_name'].replace(new RegExp(" ", 'g'), '_').toLowerCase();

                                            self.track_event(this, 'autopromo', evt_opt);
                                            break;
                                        case 'mdmProductNew':
                                            evt_opt['xtapx'] = '5';
                                            if (typeof (self.settings['product_id']) != 'undefined')
                                                evt_opt['produit'] = self.settings['product_id'].replace(new RegExp("'", 'g'), ''); // Si on n'ajoute pas d'apostrophes, la référence perd le zéro inital après appel de la fonction data_options() de foundation

                                            self.track_event(this, 'autopromo', evt_opt);
                                            break;
                                        case 'mdmProductBest':
                                            evt_opt['xtapx'] = '6';
                                            if (typeof (self.settings['product_id']) != 'undefined')
                                                evt_opt['produit'] = self.settings['product_id'].replace(new RegExp("'", 'g'), ''); // Si on n'ajoute pas d'apostrophes, la référence perd le zéro inital après appel de la fonction data_options() de foundation

                                            self.track_event(this, 'autopromo', evt_opt);
                                            break;
                                    }
                                }
                            }

                            break;
                        case "payment-mode":
                            evt_opt = {
                                category: 'footer',
                                action: 'paiement',
                                label: $(this).attr("href")
                            };

                            self.track_event(this, 'footer', evt_opt);
                            break;
                        case "shipping-mode":
                            evt_opt = {
                                category: 'footer',
                                action: 'livraison',
                                label: $(this).attr("href")
                            };

                            self.track_event(this, 'footer', evt_opt);
                            break;
                        case "condition-site":
                            evt_opt = {
                                category: 'footer',
                                action: 'conditions site',
                                label: $(this).attr("href")
                            };

                            self.track_event(this, 'footer', evt_opt);
                            break;
                        case "reassurance":
                            evt_opt = {
                                category: 'footer',
                                action: 'réassurance',
                                label: $(this).attr("href")
                            };

                            self.track_event(this, 'footer', evt_opt);
                            break;
                        case "produit-onglet":
                            evt_opt = {
                                category: 'onglet fiche produit',
                                action: self.settings['target_name']
                            };

                            self.track_event(this, 'onglet_fp', evt_opt);
                            break;
                        case "breadcrumb":
                            evt_opt = {
                                category: "fil d'ariane",
                                action: $(this).attr("href")
                            };

                            if (evt_opt.action != '#') {
                                self.track_event(this, 'fil_d_ariane', evt_opt);
                            }
                            break;
                        case "produit-gravure":
                            evt_opt = {
                                category: 'gravure',
                                action: 'saisie gravure',
                                label: tc_vars.product_name + ' - ' + tc_vars.product_id,
                                value: tc_vars.product_unitprice_ati
                            };

                            self.track_event(this, 'saisie_gravure', evt_opt);
                            break;
                        case "panier-gravure":
                            evt_opt = {
                                category: 'gravure',
                                action: 'saisie gravure',
                                label: self.settings['item_title'] + ' - ' + self.settings['item_id'].replace(new RegExp("'", 'g'), ''), // Si on n'ajoute pas d'apostrophes, la référence perd le zéro inital après appel de la fonction data_options() de foundation,
                                value: self.settings['item_price']
                            };

                            self.track_event(this, 'saisie_gravure', evt_opt);
                            break;
                        case "inscription_fidelite":
                            evt_opt = {
                                category: 'inscription fidelite après refus',
                                action: self.settings['action'],
                                label: "",
                                value: ""
                            };

                            self.track_event(this, 'inscription_fidelite', evt_opt);
                            break;
                    }
                })
                ;
        },

        reflow: function () { }
    };
}(jQuery, window, window.document));

