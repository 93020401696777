; (function ($, window, document, undefined) {
    'use strict';
    // nom de librairie à modifier
    Foundation.libs.navigation = {
        name: 'navigation',

        version: '1.0.0',

        settings: {
            callback: function () { }
        },

        init: function (scope, method, options) {
            // initialisation à conserver
            Foundation.inherit(this, '');
            var self = this;

            // à conserver
            this.bindings(method, options);
        },

        events: function () {
            // initialisation pratique à conserver
            var self = this;
            var S = this.S;
            
            // votre code ici
            this.initBindings();
        },

        initBindings: function () {
            $(this.scope)
                .off('navigation')
                .on('click.fndtn.navigation', '[' + this.attr_name() + '] a.level__link', function (e) {
                    var event = {
                        obj: e.currentTarget,
                        trigger: 'clic_menu',
                        options: {
                            category: "clic menu",
                            action: $(e.currentTarget).data("event-action"),
                            label: $(e.currentTarget).data("event-label")
                        }
                    };

                    $(document).foundation('mastertag', 'track_tc_event', event);
                }.bind(this));
        },

        reflow: function () { }
    };
}(jQuery, this, this.document));


function compteARebours(date) {
    var maintenant = new Date().getTime();
    var cible = new Date(date).getTime();

    // Calculer la différence entre la date cible et la date actuelle
    var difference = cible - maintenant;

    if (difference > 0) {
        // Convertir la différence en heures et minutes
        var heures = Math.floor(difference / (1000 * 60 * 60));
        var minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

        $('.countdown-hours').html(heures);
        $('.countdown-minutes').html(minutes);
        // Attendre 1 minute et appeler la fonction à nouveau
        setTimeout(function () {
            compteARebours(date);
        }, 60000); // 1 minute en millisecondes
    } else {
        // Fin du compte à rebours
        $('.free-chrono-shipping').html("Livraison chrono offerte*");
    }
}

$(document).ready(function () {
    //Le 01/06/2023 à 15h
    var endDate = new Date(2023, 05, 01, 15, 00, 00, 00);
    compteARebours(endDate);
});